import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.activePerson)?_c(VContainer,[_c(VRow,{staticClass:"justify-center"},[_c(VCol,{attrs:{"cols":"12","md":"5","lg":"4"}},[_c('div',{staticClass:"text-h6 font-weight-bold"},[_vm._v("About")]),_c(VList,[_c(VItemGroup,{attrs:{"color":"primary"},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},_vm._l((_vm.items),function(item,i){return _c(VListItem,{key:i,attrs:{"disabled":item.routeName === _vm.$route.name}},[_c(VListItemTitle,{on:{"click":function($event){return _vm.$router.push({name:`${item.routeName}`})}}},[_vm._v(_vm._s(item.text))])],1)}),1)],1)],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c(VCol,{attrs:{"cols":"1"}},[_c(VDivider,{attrs:{"vertical":""}})],1):_vm._e(),_c(VCol,{attrs:{"cols":"12","md":"6","lg":"7"}},[_c('router-view')],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }