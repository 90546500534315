<template>
  <v-container v-if="activePerson">
    <v-row class="justify-center">
      <v-col cols="12" md="5" lg="4">
        <div class="text-h6 font-weight-bold">About</div>
        <v-list>
        <v-item-group v-model="active" color="primary">
          <v-list-item v-for="(item,i) in items" :key="i" :disabled="item.routeName === $route.name">
            <v-list-item-title @click="$router.push({name:`${item.routeName}`})">{{item.text}}</v-list-item-title>
          </v-list-item>
        </v-item-group>
        </v-list>
      </v-col>
      <v-col cols="1" v-if="$vuetify.breakpoint.mdAndUp">
      <v-divider vertical/>
      </v-col>
      <v-col cols="12" md="6" lg="7">
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapState} from "vuex";
import {isAuthorised} from "@/util/helpers";

export default {
  name: "AboutPage",
  computed: {
    ...mapState("person",["activePerson","loggedInPerson"]),
    ...mapState("user", ["accountRoles"]),
    isEditable() {
      return this.activePerson._id === this.loggedInPerson._id || isAuthorised(this.accountRoles,["rootAdmin","admin"])
    }
  },
  data() {
    return {
      active:0,
      items: [
        {text:'Overview',routeName:'Overview'},
        {text:'Contact and Basic Info',routeName:'Contact and Basic Info'},
        {text:'In Case of Emergency Contact Info',routeName:'In Case of Emergency Contact Info'},
      ]
    }
  },

}
</script>

<style scoped>

</style>